@import "_keyframe";


/*-------------------------------
  コンテンツ画像のアニメーション
-------------------------------*/
//上へ消える
.sec.out_to_top .rollIn {
	animation: outToTopRoll 1.2s .1s ease-in forwards;
}
//下へ消える
.sec.out_to_bottom .rollIn {
	animation: outToBottomRoll 1.2s .1s ease-in forwards;
}
//下から出る
.sec.in_from_bottom .rollIn {
	opacity: 0;
	animation: inFromTopRoll 1.2s .2s ease-out forwards;
}
//上から出る
.sec.in_from_top .rollIn {
	opacity: 0;
	animation: inFromBottomRoll 1.2s .2s ease-out forwards;
}

/*-------------------------------
  テキストのアニメーション
-------------------------------*/
.text-fadeIn {
	position: relative;
	display: inline-block;
	overflow: hidden;
	span {
		opacity: 0;
		display: inline-block;
		line-height: 1.1;
	}
}
.sec.in_from_top .text-fadeIn {
	span {
		animation: inFromTopText .3s .8s ease-out forwards;
	}
}
.sec.in_from_bottom .text-fadeIn {
	span {
		animation: inFromBottomText .3s .8s ease-out forwards;
	}
}
.sec.out_to_top .text-fadeIn {
	span {
		opacity: 1;
		animation: outToTopText .5s .3s ease-out forwards;
	}
}
.sec.out_to_bottom .text-fadeIn {
	span {
		opacity: 1;
		animation: outToBottomText .5s .3s ease-out forwards;
	}
}

/*-------------------------------
  ボタンのアニメーション
-------------------------------*/
.btn-fadeIn {
	display: inline-block;
	width: 100%;
	overflow: hidden;
	a {
		opacity: 0;
		box-sizing: border-box;
		display: block;
	}
}
.sec.in_from_top .btn-fadeIn a {
	animation: inFromTopBtn .3s .8s ease-in forwards;
}
.sec.in_from_bottom .btn-fadeIn a {
	animation: inFromBottomBtn .3s .8s ease-in forwards;
}
.sec.out_to_top .btn-fadeIn a {
	opacity: 1;
	animation: outToTopBtn .5s .3s ease-out forwards;
}
.sec.out_to_bottom .btn-fadeIn a {
	opacity: 1;
	animation: outToBottomBtn .5s .3s ease-out forwards;
}
/*-------------------------------
  ロゴのアニメーション
-------------------------------*/
.logo-fadeIn {
	opacity: 1;
	transition: all .5s 1s ease;
}
.sec.in_from_top .logo-fadeIn {
	opacity: 1;
}
.sec.in_from_bottom .logo-fadeIn {
	opacity: 1;
}
.sec.out_to_top .logo-fadeIn {
	opacity: 0;
	transition-delay: 0s;
}
.sec.out_to_bottom .logo-fadeIn {
	opacity: 0;
	transition-delay: 0s;
}

/*-------------------------------
  blogリンクのアニメーション
-------------------------------*/
.list_link_fadeIn {
	opacity: 0;
	transition: all .5s .8s ease;
}
.sec.in_from_top .list_link_fadeIn {
	opacity: 1;
	transition: all .5s .8s ease;
}
.sec.in_from_bottom .list_link_fadeIn {
	opacity: 1;
	transition: all .5s .8s ease;
}
.sec.out_to_top .list_link_fadeIn {
	opacity: 0;
	transition: all .5s .5s ease;
}
.sec.out_to_bottom .list_link_fadeIn {
	opacity: 0;
	transition: all .5s .5s ease;
}

/*-------------------------------
  背景のアニメーション
-------------------------------*/
//上へ消える
.bg_sec.out_to_top {
	animation: outToTopBg .8s ease-out forwards;
}
//下へ消える
.bg_sec.out_to_bottom {
	animation: outToBottomBg .8s ease-out forwards;
}
//下から出る
.bg_sec.in_from_bottom {
	animation: inFromTopBg 1.0s ease-out forwards;
}
//上から出る
.bg_sec.in_from_top {
	animation: inFromBottomBg 1.0s ease-out forwards;
}
