@import "_variables";
@import "_mixin";

//iconfont setting
%iconfont {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: top;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

//font-english family
%fontEnBook{
	font-family: $font-EN;
	font-weight: 400;
}
%fontEnMedium{
	font-family: $font-EN;
	font-weight: 500;
}
%fontEnMediumI{
	@extend %fontEnMedium;
	font-style: italic;
}
%fontEnBold{
	font-family: $font-EN;
	font-weight: 700;
}
%fontEnBoldI{
	@extend %fontEnBold;
	font-style: italic;
}

//layout
%listThumbnail{
	>ul{
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;
		>li{
			flex: none;
			opacity: 0;
			transition: all 0.5s ease;
			&.in{
				opacity: 1;
			}
			a{
				display: block;
				text-align: center;
				text-decoration: none;
				border: none;
				figure{
					display: block;
					span{
						width: 100%;
						height: 0;
						display: block;
						position: relative;
						overflow: hidden;
						img{
							width: 100%;
							height: auto;
							position: absolute;
							top: 0;
							left: 0;
							transition: all 0.3s ease;
						}
					}
					figcaption{
						
					}
				}
				&:hover{
					figure{
						span{
							img{
								transform: scale(1.2);
								@include max-screen($breakpoint-tablet){
									transform: scale(1);
								}
							}
						}
					}
				}
			}
		}
	}
}
%listWorks{
	@extend %listThumbnail;
	>ul{
		>li{
			@include max-screen($breakpoint-tablet){
				width: 100% !important;
				margin-bottom: 30px;
			}
			a{
				position: relative;
				overflow: hidden;
				color: #fff;
				figure{
					>span{
						padding-top: 58.18%;
					}
					figcaption{
						width: 100%;
						height: 100%;
						border: solid 0px #fff;
						box-sizing: border-box;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-wrap: wrap;
						flex-direction: column;
						position: absolute;
						top: 0;
						left: 0;
						z-index: 2;
						transition: all 0.3s ease;
						//transform: translate(0,100%);
						opacity: 0;
						@include max-screen($breakpoint-tablet){
							width: auto;
							height: auto;
							padding: 10px;
							position: relative;
							display: block;
							text-align: left;
							opacity: 1;
							transform: translate(0,0);
						}
						.date{
							transform: translate(0,10px);
							opacity: 0;
							transition: all 0.3s ease;
							@include max-screen($breakpoint-tablet){
								transform: translate(0);
								opacity: 1;
							}
						}
						.name{
							transform: translate(0,10px);
							opacity: 0;
							transition: all 0.3s ease;
							@include max-screen($breakpoint-tablet){
								transform: translate(0);
								opacity: 1;
							}
						}
						.media{
							font-size: 0;
							text-align: center;
							transform: translate(0,10px);
							opacity: 0;
							transition: all 0.3s ease;
							@include max-screen($breakpoint-tablet){
								text-align: left;
								transform: translate(0);
								opacity: 1;
							}
							li{
								display: inline-block;
								+li{
									margin-left: 0.5em;
								}
							}
						}
					}
				}
				&:hover{
					figure{
						figcaption{
							border-width: 10px;
							opacity: 1;
							//transform: translate(0,0);
							@include max-screen($breakpoint-tablet){
								border-width: 0;
							}
							.date{
								transform: translate(0);
								opacity: 1;
							}
							.name{
								transform: translate(0);
								opacity: 1;
							}
							.media{
								transform: translate(0);
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}

//基本のコンテンツ幅
%maxwidth{
	width: 80%;
	max-width: 1100px;
	margin: 0 auto;
	@include max-screen($breakpoint-mobile-portrait){
		width: auto;
		margin: 0 16px;
	}
}

//title_style
%ttlMain{
	margin-bottom: 80px;
	color: $color-orange;
	@extend %fontEnBoldI;
	font-size: 80px;
	line-height: 1;
	text-align: center;
	@include screen($breakpoint-mobile, $breakpoint-pc-small){
		margin-bottom: 8.5vw;
		font-size: 8.5vw;
	}
	@include max-screen($breakpoint-mobile-portrait){
		margin-bottom: 40px;
		font-size: 40px;
	}
	span.ttlSub{
		font-size:36px;
		display:block;
		font-family:$font_yugo;
		font-style:normal;
		letter-spacing:4px;
		margin-top:18px;
		@include max-screen($breakpoint-mobile-portrait){
			font-size:22px;
			letter-spacing:2px;
			margin-top:10px;
		}
	}
}
%ttlBgfill{
	background: $color-orange;
	padding: 13px 30px;
	color: #fff;
	font-size: 24px;
	line-height: 1;
	@include max-screen($breakpoint-tablet){
		padding: 13px 15px;
		font-size: 18px;
		line-height: 1.2;
	}
}

//インプット部分の初期スタイル削除（iOS）
%input{
	appearance: none;
	background: none;
	border: none;
	border-radius: 0;
}

//checkbox、radio、selectのスタイル
%clickparts{ //checkbox、radio両方に共通するスタイル（labelにかける）
	@include max-screen($breakpoint-mobile-portrait){
		display: block;
		margin-right: 0;
		border: solid 1px #efefef;
		position: relative;
		text-align: center;
		line-height: 50px;
	}
	&:before{
		@include max-screen($breakpoint-mobile-portrait){
			width: 20px;
			height: 20px;
			margin-top: -11px;
			border: solid 1px #cacaca;
			display: block;
			position: absolute;
			top: 50%;
			left: 30px;
			content: "";
		}
	}
	&:after{
		@include max-screen($breakpoint-mobile-portrait){
			display: block;
			position: absolute;
			top: 50%;
			content: "";
			opacity: 0;
			transition: all 0.3s ease;
		}
	}
	&.check:after{
		opacity: 1;
	}
	input{
		font-size: 16px;
		@include max-screen($breakpoint-mobile-portrait){
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
		}
	}
}
%checkbox{ //radio特有のスタイル（radioのlabelに適用する）
	@extend %clickparts;
	&:after{
		@include max-screen($breakpoint-mobile-portrait){
			width: 26px;
			height: 20px;
			margin-top: -17px;
			border: 2px $color-orange;
			border-style: none none solid solid;
			left: 30px;
			transform: rotate(-50deg) scale(1,0.5) skewY(20deg);
		}
	}
}
%radio{ //radio特有のスタイル（radioのlabelに適用する）
	@extend %clickparts;
	&:before{
		@include max-screen($breakpoint-mobile-portrait){
			border-radius: 100%;
		}
	}
	&:after{
		@include max-screen($breakpoint-mobile-portrait){
			background: $color-orange;
			width: 14px;
			height: 14px;
			margin: -7px 0 0 -7px;
			border-radius: 100%;
			left: 41px;
		}
	}
}
%select{//selectのlabelに手供養する
	padding: 10px 30px 10px 0;
	border: solid 1px #efefef;
	overflow: hidden;
	position: relative;
	z-index: 2;
	@include max-screen($breakpoint-mobile-portrait){
		width: 100%;
		box-sizing: border-box;
	}
	&:after{
		width: 0;
		height: 0;
		margin-top: -5px;
		border-color: $color-orange transparent transparent;
		border-style: solid;
		border-width: 10px 8px 0;
		display: block;
		position: absolute;
		top: 50%;
		right: 10px;
		z-index: -1;
		content: "";
	}
	select{
		width: 150%;
		padding-right: 60px;
		padding-left: 10px;
		box-sizing: border-box;
		@extend %input;
		font-size: 16px;
		white-space: normal;
		&::-ms-expand {
			display: none;
		}
	}
}

//aboutページのリストスタイル
%list_panc{// 区切りが「/」のリスト親のulにかける
	font-size: 0;
	li{
		display: inline-block;
		font-size: 16px;
		&:not(:last-child):after{
			margin: 0 0.5em;
			display: inline-block;
			content: "/";
		}
	}
}

%list_multi{//タイトルあり繰り返しあり、子のリストに区切り有のリストulにかける
	li{
		+li{
			margin-top: 1em;
		}
		h3{
			font-size: 16px;
			font-weight: bold;
		}
		ul{
			@extend %list_panc;
		}
	}
}


//ブログカテゴリー部分のスタイル
%cate{
	width: 100px;
	padding: 8px 0;
	color: #fff;
	text-align: center;
	@include max-screen($breakpoint-tablet){
		display: block;
		margin-bottom: 15px;
	}
	$colors: $color-cat01, $color-cat02, $color-cat03,$color-cat04, $color-cat05, $color-cat06, $color-cat07;
	@for $i from 1 through 7 {
		&.cate0#{$i}{
			background: nth($colors, $i);
		}
	}
}

//blogヘッダ用エクステンド
%posthead{
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	.ttl{
		width: 100%;
		margin: 20px 0 30px;
		order: 2;
		font-size: 24px;
		font-weight: bold;
		line-height: 1.2;
	}
	.head{
		order: 1;
		>*{
			display: inline-block;
			font-size: 14px;
			font-weight: bold;
			vertical-align: middle;
		}
		.cate{
			@extend %cate;
		}
		.date{}
		.author{}
	}
}

//blogリスト用エクステンド
%post{
	padding: 30px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	@include max-screen($breakpoint-tablet){
		display: block;
	}
	@include max-screen($breakpoint-mobile-portrait){
		padding: 15px;
	}
	.txtArea{
		width: calc(72% - 30px);
		@extend %posthead;
		order: 2;
		flex: none;
		@include max-screen($breakpoint-tablet){
			width: auto !important;
		}
		.sentence{
			width: 100%;
			order: 3;
			margin-bottom: 20px;
			font-size: 16px;
			line-height: 1.625;
		}
		.more{
			width: 100%;
			flex: none;
			order: 4;
			text-align: right;
			a{
				border: none;
				@extend %fontEnMedium;
				color: #000;
				font-size: 16px;
				line-height: 18px;
				text-decoration: none;
				&:after{
					padding-left: 20px;
					@extend %iconfont;
					content: $icon_arrow_right;
				}
			}
		}
	}
	.imgArea{
		width: 28%;
		flex: none;
		order: 1;
		@include max-screen($breakpoint-tablet){
			display: none;
		}
		img{
			width: 100%;
			height: auto;
		}
	}
}

//ボタンの基本スタイル
%btn{
	background: $color-orange;
	width: 100%;
	max-width: 250px;
	margin: 0 auto;
	padding: 0 48px;
	border: none !important;
	box-sizing: border-box;
	position: relative;
	color: #fff !important;
	font-size: 16px;
	line-height: 60px;
	white-space: nowrap;
}
//aタグにボタンのスタイルをつけるとき
%btn_a{
	@extend %btn;
	display: block;
	text-align: center;
	text-decoration: none;
}
//フォームパーツにボタンのスタイルをつけるとき
%btn_form{
	@extend %input;
	@extend %btn;
	cursor: pointer;
}
//リストへ戻るためのリンクスタイル
%btn_allarea_back{
	@extend %btn_a;
	max-width: none;
	padding: 70px 10px;
	font-size: 20px;
	line-height: 1.2;
	white-space: normal;
	@include max-screen($breakpoint-mobile-portrait){
		padding: 41px 10px;
		font-size: 18px;
	}
	&:before{
		padding-right: 20px;
		@extend %iconfont;
		content: $icon_arrow_left;
		display: inline-block;
		@include max-screen($breakpoint-mobile-portrait){
		}
	}
}