@import "_variables";
/*==================
 ▼ブレイクポイントのルール▼
 -スマホを基本設計にする-
 *〜479px：SP縦
 *480px〜：SP横
 *600px〜タブレット
 *960px〜小型PC
 *1280px〜大型PC
http://nuconeco.net/responsive-ui-breakpoints/
上記URL参照
==================*/

// break points
$breakpoint-pc-big: 1280px;
$breakpoint-pc-small: 960px;
$breakpoint-tablet: 600px;
$breakpoint-mobile: 480px;
$breakpoint-mobile-portrait: 479px;

@mixin max-screen($break-point) {
	@media screen and (max-width: $break-point) {
		@content;
	}
}
@mixin min-screen($break-point) {
	@media screen and (min-width: $break-point) {
		@content;
	}
}
@mixin screen($break-point-min, $break-point-max) {
	@media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
		@content;
	}
}

@mixin pc($width: 768px) {
	@media screen and (min-width: $width) {
		@content;
	}
}

@mixin sp($width: 767px) {
	@media screen and (max-width: $width) {
		@content;
	}
}

/*
 * Mixin for placeholder
 * @include placeholderColor(#00ff00);
 */
@mixin placeholderColor($color) {
	&:placeholder-shown {
		color: $color;
	}
	&::-webkit-input-placeholder {
		color: $color;
	}
	&:-moz-placeholder {
		color: $color;
		opacity: 1;
	}
	&::-moz-placeholder {
		color: $color;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: $color;
	}
}
