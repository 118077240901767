@charset "utf-8";
@import "_variables";
@import "_extend";
@import "_mixin";
@import "_animation";


/* ********************************************************
	add style CSS
******************************************************** */
body {
	position: fixed;
	bottom: 0;
	left: 0;
	background: #ffffff;
	background: -moz-radial-gradient(center, ellipse cover, #ffffff 75%, #dddddd 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #ffffff 75%,#dddddd 100%);
	background: radial-gradient(ellipse at center, #ffffff 75%,#dddddd 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dddddd',GradientType=1 );
}

#pageFooter .footerInner:after {
	display: none;
}
/*-------------------------------
  common
-------------------------------*/
@keyframes backgroundGradation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
video {
	position: fixed;
	top: 0;
	left: 0;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	left: 50%;
  transform: translateX(-50%);
}
.frame {
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	// overflow: hidden;
	// position: fixed;
}
.wrap_bg {
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: +1;

	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	.bg_sec {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
		overflow: hidden;
	}
	.bg_sec01 {
		// background:url(../images/index/bg_top.jpg) center no-repeat;
		// background-size: cover;
	}
	.bg_sec02 {
		background:url(../images/index/bg_top_01.jpg) center no-repeat;
		background-size: cover;
		@include max-screen(900px){
			background-image:url("../images/index/bg_top_01_sp.jpg");
		}
	}
	.bg_sec03 {
		.bg_sec_inner {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: #f09819;
			background: -webkit-linear-gradient(bottom left, #faee87, #f09819, #ff5858);
			background: -o-linear-gradient(bottom left, #faee87, #f09819, #ff5858);
			background: linear-gradient(to top right, #faee87, #f09819, #ff5858);
			background-size: 600% 600%;
			animation: backgroundGradation 10s ease infinite;
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09819', endColorstr='#ff5858',GradientType=1 );
		}
	}
	.bg_sec04 {
		background:url(../images/index/bg_top_02.jpg) center no-repeat;
		background-size: cover;
	}
	.bg_sec05 {
		.bg_sec_inner {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: #f09819;
			background: -webkit-linear-gradient(bottom left, #faee87, #f09819, #ff5858);
			background: -o-linear-gradient(bottom left, #faee87, #f09819, #ff5858);
			background: linear-gradient(to top right, #faee87, #f09819, #ff5858);
			background-size: 600% 600%;
			animation: backgroundGradation 10s ease infinite;
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09819', endColorstr='#ff5858',GradientType=1 );
		}
	}

.bg_sec06 {
		position: relative;
		background:url(../images/index/bg_top_03.jpg) center no-repeat;
		background-size: cover;
		@include max-screen($breakpoint-mobile) {
      background: none;
		}
		&:before {
			@include max-screen($breakpoint-mobile) {
				content: "";
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				background: rgba(255,255,255,0.5);
				position: absolute;
				top: 0;
				bottom: 0;
			}
		}
	}
	.layer {
		width: 100%;
		height: 100%;
		background:url(../images/index/ptn_top.png) left top repeat;
		position: absolute;
		top: 0;
		left: 0;
		z-index: +1;
	}
}

.btn_contact {
	position: fixed;
	display: block;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	width: 30%;
	max-width: 152px;
	background: url(../images/index/btn_contact.svg)no-repeat center center/100% auto;
	z-index: 99;
	bottom: 2vw;
	left: 2vw;
	z-index: 9;
	&:after {
		content: "";
		display: block;
		padding-top: 53.94%;
	}
	@include min-screen($breakpoint-tablet) {
		&:hover {
			animation-name: bounce;
			transform-origin: center bottom;
			animation-iteration-count: infinite;
		  animation-duration: 1s;
		  animation-fill-mode: both;
		}
	}
}

.sec {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	font-size: 62.5%;
	position: relative;
	z-index: +3;
	.sec_content {
		position: relative;
		z-index: +2;
	}
	.sec_inner {
		width: 100%;
		max-width: 1000px;
		padding: 0 10px;
		margin: 0 auto;
		position: relative;
		z-index: +2;
	}
}

.btn-fadeIn {
	margin-top: 2.5vh;
}
.btn_readmore {
	display: block;
	width: 250px;
	height: 60px;
	line-height: 60px;
	font-size: 15px;
	font-family: $font-EN;
	font-style: italic;
	text-align: center;
	position: relative;
	transition: color .2s ease,background-color .2s ease,opacity .5s ease-in;
	text-decoration: none;
	cursor: pointer;
	@include max-screen($breakpoint-mobile) {
		width: 100%;
	}
	&.orange {
		color: #fff;
		background: #F15D30;
	}
	&.white {
		color: #F15D30;
		background: #fff;
	}
	&.ghost {
		background: transparent;
		border: 1px solid #fff;
		color: #fff;
	}
	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
	}
	&:before {
		width: 12px;
		height: 12px;
		border-top: 1px solid #fff;
		border-right: 1px solid #fff;
		top: 0;
		bottom: 0;
		right: 20px;
		margin: auto;
		transform: rotateZ(45deg);
		transition: all .4s ease-in-out;
	}
	&:after {
		width: 15px;
		height: 1px;
		background: #fff;
		top: 0;
		bottom: 0;
		right: 20px;
		margin: auto;
		transition: all .4s ease-in-out;
	}
	&.orange:before {
		border-color: #fff;
	}
	&.orange:after {
		background-color: #fff;
	}
	&.white:before {
		border-color: #F15D30;
	}
	&.white:after {
		background-color: #F15D30;
	}
	&.white:before {
		border-color: #F15D30;
	}
	&.white:after {
		background-color: #F15D30;
	}
	@include min-screen($breakpoint-tablet) {
		&:hover {
			&:before {
				right: 15px;
			}
			&:after {
				width: 30px;
				right: 15px;
			}
		}
	}
	@include min-screen($breakpoint-tablet) {
		&.orange:hover {
			background: #fff;
			color: #F15D30;
			&:before {
				border-color: #F15D30;
			}
			&:after {
				background: #F15D30;
			}
		}
	}
	@include min-screen($breakpoint-tablet) {
		&.white:hover {
			background: #F15D30;
			color: #fff;
			&:before {
				border-color: #fff;
			}
			&:after {
				background: #fff;
			}
		}
	}
	@include min-screen($breakpoint-tablet) {
		&.ghost:hover {
			background: #fff;
			color: #F15D30;
			&:before {
				border-color: #F15D30;
			}
			&:after {
				background: #F15D30;
			}
		}
	}
}



.controller {
	position: fixed;
	top: 50%;
	right: 2%;
	margin-top: -85px;
	z-index: 9;
	list-style: none;
	@include max-screen($breakpoint-mobile) {
		visibility: hidden;
	}
	.controller_item {
		&+.controller_item {
			margin-top: 16px;
		}
		a {
			position: relative;
			font-size: 18px;
			@extend %fontEnMedium;
			height: 18px;
			line-height: 1;
			color: #F15D30;
			display: block;
			text-align: right;
			cursor: pointer;
			span {
				transition: transform .2s ease;
				display: block;
				padding-right: 20px;
			}
			&:after {
				content: "";
				display: block;
				width: 8px;
				height: 8px;
				background: #F15D30;
				border-radius: 50%;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto;
			}
			@include min-screen($breakpoint-tablet) {
				&:hover {
					color: #000;
					&:after {
						background: #000;
					}
					span {
						transform: scaleY(1);
						display: block;
					}
				}
			}
			&.current{
				span {
					color: #000;
					transform: scaleY(1);
					display: block;
				}
				&:after {
					background: #000;
				}
			}
		}
	}
}

.bnr_link {
  display: block;
  position: fixed;
	bottom: 80px;
	right: 2%;
	z-index: 9;
 /* &_pc {
    display: block;
    @include max-screen($breakpoint-mobile) {
      display: none;
    }
  }*/

	@include sp {
		bottom: 90px;
	}
}

.bnrInfo {
	display: flex;
	justify-content: flex-end;
	list-style: none;

	@include sp {
		display: block;
	}

	&_list {
		width: 240px;

		@include sp {
			width: 47vw;
		}

		& + & {
			@include pc {
				margin-left: 20px;
			}

			@include sp {
				margin-top: 10px;
			}
		}
	}

	&_item {
		width: 100%;
		display: block;
		position: relative;
		z-index: 1;

		@include pc {
			&:after {
				content: "";
				width: 100%;
				height: 100%;
				background-color: #fff;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				opacity: 0;
				border-radius: 10px;
				transition: opacity .3s ease;
			}

			&:hover {
				&:after {
					opacity: .3;
				}
			}
		}
	}

	img {
		width: 100%;
	}
}

.sec {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
	.sec_content {
		width: 75%;
		max-width: 780px;
		margin-left: 10%;
		@include max-screen($breakpoint-mobile) {
			width: 90%;
			margin: -18% auto 0;
		}
		.ttl_sec {
			font-family: $font-EN;
			font-style: italic;
			font-size: 75px;
			line-height: 1;
			display: inline-block;
			@include max-screen($breakpoint-pc-small) {
				font-size: 60px;
			}
			@include max-screen($breakpoint-tablet) {
				font-size: 45px;
			}
			@include max-screen($breakpoint-mobile) {
				font-size: 35px;
			}
			.sub {
				display: inline-block;
				font-size: 30px;
				@include max-screen($breakpoint-pc-small) {
					font-size: 20px;
				}
				@include max-screen($breakpoint-tablet) {
					font-size: 18px;
				}
				@include max-screen($breakpoint-mobile) {
					font-size: 16px;
				}
			}
			&.orange {
				color: #F15D30;
			}
			&.white {
				color: #fff;
			}
		}
		.text_sec {
			font-size: 24px;
			color: #fff;
			max-width: 700px;
			margin-top: 4vh;
			&.orange {
				color: #F15D30;
			}
			span {
				line-height: 1.8!important;
				@include max-screen($breakpoint-mobile) {
					line-height: 1.6!important;
				}
			}
			@include max-screen($breakpoint-tablet) {
				font-size: 14px;
			}
			@include max-screen($breakpoint-mobile) {
				font-size: 12px;
				margin-top:10px;
			}
		}
	}
	&.sec02 .sec_content {
		@include max-screen($breakpoint-mobile) {
			margin-top: -23%;
		}
	}
	&.sec05 .sec_content {
		@include max-screen($breakpoint-mobile) {
			margin-top: -23%;
		}
	}
}



/*-------------------------------
  sec01
-------------------------------*/
.sec01 {
	display: flex;
	justify-content: center;
	align-items: center;
	.logo_wrap {
		width: 18.57%;
		min-width: 240px;
		@include max-screen($breakpoint-mobile-portrait){
			min-width: 180px;
		}
		.logo_top {
			text-indent: 100%;
			overflow: hidden;
			white-space: nowrap;
			background: url(../images/index/main_logo.svg) center no-repeat;
			background-size: 100% auto;
			&:after {
				content:"";
				display: block;
				padding-top: 96.15%;
			}
		}
		.btn_playMovie {
			display: block;
			cursor: pointer;
			text-decoration: none;
			.btn_body {
				width: 28.46%;
				display: block;
				background-color: #F15D30;
				border-radius: 50%;
				margin: 10% auto 0;
				position: relative;
				transition: background-color .25s ease;
				&:before {
					content: "";
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 10px 0 10px 14px;
					border-color: transparent transparent transparent #fff;
					position: absolute;
					top: 0;
					left: 7px;
					bottom: 0;
					right: 0;
					margin: auto;
				}
				&:after {
					content: "";
					display: block;
					padding-top: 100%;
				}
			}
			.btn_text {
				transition: color .25s ease;
				color: #fff;
				font-size: 12px;
				display: block;
				text-align: center;
				margin-top: 3%;
				font-family: $font-EN;
			}
			@include min-screen($breakpoint-tablet) {
				&:hover {
					.btn_body {
						background-color: #fff;
						&:before {
							border-color: transparent transparent transparent #F15D30;
						}
					}
					.btn_text {
						color: #F15D30;
					}
				}
			}
		}
		.btn_scroll {
			width: 40px;
			height: 70px;
			display: block;
			color: #fff;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			cursor: pointer;
			@include max-screen($breakpoint-mobile) {
				height: 80px;
			}
			&:before,
			&:after {
				content: "";
				display: block;
				position: absolute;
				width: 1px;
				height: 70%;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				background: #fff;
				@include max-screen($breakpoint-mobile) {
					height: 85%;
				}
			}
			&:after {
				width: 5px;
				transform: translateY(100%);
				background: #F15D30;
				opacity: 0;
				transition: transform .2s ease-in,opacity .4s ease-in;
			}
			@include min-screen($breakpoint-tablet) {
				&:hover {
					color: #F15D30;
					&:after {
						opacity: 1;
						transform: translateY(0);
					}
				}
			}
		}
	}
}
/*-------------------------------
  sec03
-------------------------------*/
.sec03 {
	.img_area {
		position: absolute;
		max-width: 756px;
		width: 65%;
		background: #000;
		top: 0;
		bottom: 0;
		right: 0;
		background: url(../images/index/img_top_01.jpg) center no-repeat;
		background-size: 100% auto;
		@include max-screen($breakpoint-tablet) {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: 90% 50%;
		}
		&:after {
			content: "";
			display: block;
			padding-top: 59.52%;
			@include max-screen($breakpoint-tablet) {
				padding-top: 0;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.3) url(../images/index/ptn_top.png) left top repeat;
			}
		}
	}
}
/*-------------------------------
  sec06
-------------------------------*/
.sec06 {
	.list_link {
		list-style: none;
		margin-top: 4vh;
		box-sizing: border-box;
		padding: 10px 15px;
		background: rgba(255,255,255,0.5);

		.list_item {
			font-size: 16px;
			line-height: 1.4;
      display: block;
			@include max-screen($breakpoint-tablet) {
				font-size: 14px;
			}
			@include max-screen($breakpoint-mobile) {
				font-size: 13px;
			}
			&+.list_item {
				margin-top: 2vh;
			}
			a {
				cursor: pointer;
				position: relative;
				display: inline-block;
				text-decoration: none;
				color: #000;
				transition: color .2s ease-in-out;
				.label {
					width: 85px;
					padding: 5px;
					box-sizing: border-box;
					text-align: center;
					color: #fff;
					background-color: #036BA9;
				}
				.time {
					margin-left: 15px;
				}
				@include min-screen($breakpoint-tablet) {
					&:hover {
						color: #F15D30;
						.time {
							opacity: .8;
						}
					}
				}
				.ttl_link {
					margin-left: 15px;
					margin-top: 5px;
					display: inline-block;
					@include max-screen($breakpoint-mobile-portrait){
						display: block;
					}
				}
			}
		}
	}
}

/*-------------------------------
  footer
-------------------------------*/
#pageFooter{
	background: none;
	padding: 0;
	.footerInner{
		padding: 0;
		border-top: none;
		#copyright{
			margin-top: auto;
			float: none;
			position: fixed;
			bottom: 20px;
			right: 20px;
			z-index: +3;
			@include max-screen($breakpoint-mobile) {
				bottom: 20px;
				right: 20px;
			}
		}
	}
}


#layer {
	width: 100%;
	height: 100%;
	background: rgba(241,93,48,.95);
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	color: #fff;
	font-weight: bold;
	opacity: 0;
}
#layer.show {
	opacity: 1;
}


/* 2022 05 31 */
.display-block {
	display: block !important;
}

.sec_flex {
	display: flex;
	margin:0 auto;
	width:100%;
	@include max-screen($breakpoint-mobile) {
		flex-direction: column;
		height:calc(100% - 60px);
		margin-top:60px;
	}
}

.sec_content--col {
	flex: 1;
	@include max-screen($breakpoint-mobile) {
		padding-bottom: 30px !important;
		box-sizing: border-box;
	}
}

.sec.sec02 .sec_content {
	@include max-screen($breakpoint-mobile) {
		margin-top:0;
	}
}

.sec_flex .sec_content--col {
	max-width: 510px;
	margin-left: auto;
	margin-right: auto;
	padding:0 20px;
	box-sizing: border-box;

	.text_sec {
		font-size: 16px;
		@include max-screen($breakpoint-mobile) {
			font-size: 12px;
		}
	}
}

