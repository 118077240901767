/*-------------------------------
  コンテンツのアニメーション
-------------------------------*/
@keyframes outToTopRoll {
  30% {
    opacity: 1;
    transform: scale3d(0.9, 0.9, 0.9) translate3d(0, 0, 0);
    // animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

	60% {
		opacity: 0;
	}


  to {
    opacity: 0;
    transform: scale3d(1.2, 1.2, 1.2) translate3d(-300px, -1000px, 0);
    // animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes outToBottomRoll {
  30% {
    opacity: 1;
    transform: scale3d(0.9, 0.9, 0.9) translate3d(0, 0, 0);
    // animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

	60% {
		opacity: 0;
	}


  to {
    opacity: 0;
    transform: scale3d(1.2, 1.2, 1.2) translate3d(300px, 1000px, 0);
    // animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes inFromBottomRoll {
  from {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9) translate3d(300px, 1000px, 0);
    // animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

	60% {
		opacity: 0;
	}

	90% {
		transform: scale3d(0.9, 0.9, 0.9) translate3d(0, 0, 0);
	}

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    // animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes inFromTopRoll {
  from {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9) translate3d(-300px, -1000px, 0);
    // animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

	60% {
		opacity: 0;
	}

	90% {
		transform: scale3d(0.9, 0.9, 0.9) translate3d(0, 0, 0);
	}

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    // animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}


/*-------------------------------
  背景のアニメーション
-------------------------------*/
@keyframes outToTopBg {
	from {
    animation-timing-function: cubic-bezier(.12,.07,.55,-0.06);
		transform-origin: center;
  }
	65% {
		opacity: 1;
		transform: scale3d(0.9, 0.9, 0.9) translate3d(2.5%, -14%, 0);
		animation-timing-function: cubic-bezier(.12,.07,.55,-0.06);
	}
  100% {
		opacity: 0;
		transform: scale3d(0.8, 0.8, 0.8) translate3d(25%, -140%, 0);
  }
}
@keyframes outToBottomBg {
	from {
		animation-timing-function: cubic-bezier(.12,.07,.55,-0.06);
		transform-origin: center;
  }
	65% {
		opacity: 1;
		transform: scale3d(0.9, 0.9, 0.9) translate3d(-2.5%, 14%, 0);
		animation-timing-function: cubic-bezier(.12,.07,.55,-0.06);
	}
  100% {
		opacity: 0;
		transform: scale3d(0.8, 0.8, 0.8) translate3d(-25%, 140%, 0);
  }
}

@keyframes inFromBottomBg {
	from {
    transform: scale3d(0.85, 0.85, 0.85) translate3d(-25%, 140%, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		transform-origin: center;
  }

	80% {
		transform: scale3d(0.85, 0.85, 0.85) translate3d(-5%, 28%, 0);
	}

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes inFromTopBg {
  from {
    transform: scale3d(0.85, 0.85, 0.85) translate3d(25%, -140%, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		transform-origin: center;
  }

	80% {
		transform: scale3d(0.85, 0.85, 0.85) translate3d(5%, -28%, 0);
	}

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

/*-------------------------------
  テキストのアニメーション
-------------------------------*/
@keyframes outToTopText {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -120%, 0);
    // animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes outToBottomText {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 120%, 0);
    // animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes inFromBottomText {
  from {
    opacity: 0;
    transform: translate3d(0, -120%, 0);
    // animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 1;
		transform: translate3d(0, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes inFromTopText {
  from {
    opacity: 0;
		transform: translate3d(0, 120%, 0);
    // animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 1;
		transform: translate3d(0, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}


/*-------------------------------
  ボタンのアニメーション
-------------------------------*/
@keyframes outToTopBtn {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 1;
    transform: translate3d(0, -120%, 0);
    transform-origin: center bottom;
  }
}
@keyframes outToBottomBtn {
	from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 1;
		transform: translate3d(0, 120%, 0);
    transform-origin: center bottom;
  }
}
@keyframes inFromBottomBtn {
  from {
    opacity: 0;
		transform: translate3d(0, -120%, 0);
    // animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 1;
		transform: translate3d(0, 0, 0);
    // animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes inFromTopBtn {
  from {
    opacity: 0;
		transform: translate3d(0, 120%, 0);
    // animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 1;
		transform: translate3d(0, 0, 0);
    // animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}


/*-------------------------------
  コンタクトフォームボタン
-------------------------------*/
.animated.infinite {
  animation-iteration-count: infinite;
}
@keyframes bounce {
  0%, 100%, 20%, 53%, 80% {
    transition-timing-function: cubic-bezier(0.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)
  }
  40%,
  43% {
    transition-timing-function: cubic-bezier(0.755, .050, .855, .060);
    transform: translate3d(0, -30px, 0)
  }
  70% {
    transition-timing-function: cubic-bezier(0.755, .050, .855, .060);
    transform: translate3d(0, -15px, 0)
  }
  90% {
    transform: translate3d(0, -4px, 0)
  }
}
