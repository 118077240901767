//カラー
$color-orange: #f15d30;
$color-cat01: #e6377c;
$color-cat02: #f15d30;
$color-cat03: #f8b62d;
$color-cat04: #47be73;
$color-cat05: #00b8c9;
$color-cat06: #036ba9;
$color-cat07: #5d3cb2;

//フォント指定
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
$font_noto: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
$font_yugo: "Yu Gothic", YuGothic , "メイリオ", sans-serif;
$font-EN: 'Montserrat', sans-serif;
$font_yumin: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;

//アイコンフォント
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot');
  src:  url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

$icon_arrow_bottom: "\e900"; //下向き矢印
$icon_arrow_left: "\e901"; //左向き矢印
$icon_arrow_right: "\e902"; //右向き矢印
$icon_arrow_top: "\e903"; //上向き矢印

